import React, { useEffect, useState } from "react";
import axios from "axios";

const Creditors = () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
    const [creditors, setCreditors] = useState([]);
    const [supplierId, setSupplierId] = useState("");
    const [supplierName, setSupplierName] = useState("");
    const [pagination, setPagination] = useState({
        page: 1,
        page_size: 20,
        total: 0,
        total_pages: 0
    });

    // Fetch creditors data with filters and pagination
    const fetchCreditors = async () => {
        try {
            const response = await axios.get(`${backendUrl}/payable/accountsPayable/getCreditors`, {
                params: {
                    supplier_id: supplierId,
                    supplier_name: supplierName,
                    page: pagination.page,
                    page_size: pagination.page_size
                }
            });
            setCreditors(response.data.data);
            setPagination({
                ...pagination,
                total: response.data.total,
                total_pages: response.data.total_pages
            });
        } catch (error) {
            console.error("Error fetching creditors:", error);
        }
    };

    // Handle filter and pagination changes
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        if (name === "supplier_id") setSupplierId(value);
        if (name === "supplier_name") setSupplierName(value);
    };

    const handlePageChange = (newPage) => {
        setPagination((prev) => ({
            ...prev,
            page: newPage
        }));
    };

    const handlePageSizeChange = (e) => {
        setPagination((prev) => ({
            ...prev,
            page_size: parseInt(e.target.value, 10),
            page: 1
        }));
    };

    useEffect(() => {
        fetchCreditors();
    }, [supplierId, supplierName, pagination.page, pagination.page_size]);

    return (
        <div className="mx-10 mt-5 text-xl">
            <h2 className="text-3xl font-semibold mb-4">Creditors</h2>

            {/* Filters */}
            <div className="flex flex-wrap gap-4 mb-5">
                <input
                    type="text"
                    name="supplier_id"
                    placeholder="Supplier ID"
                    value={supplierId}
                    onChange={handleFilterChange}
                    className="border p-2 rounded"
                />
                <input
                    type="text"
                    name="supplier_name"
                    placeholder="Supplier Name"
                    value={supplierName}
                    onChange={handleFilterChange}
                    className="border p-2 rounded"
                />
            </div>

            {/* Creditors Table */}
            <table className="min-w-full bg-white border border-gray-300">
                <thead>
                <tr>
                    <th className="px-4 py-2 border">Supplier ID</th>
                    <th className="px-4 py-2 border">Supplier Name</th>
                    <th className="px-4 py-2 border">Unsettled Amount</th>
                </tr>
                </thead>
                <tbody>
                {creditors.map((creditor) => (
                    <tr key={creditor.supplier_id}>
                        <td className="px-4 py-2 border">{creditor.supplier_id}</td>
                        <td className="px-4 py-2 border">{creditor.supplier_name}</td>
                        <td className="px-4 py-2 border">{creditor.unsettled_amount}</td>
                    </tr>
                ))}
                </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="flex justify-between items-center mt-4">
                <div>
                    Page {pagination.page} of {pagination.total_pages}
                </div>
                <div className="flex items-center gap-2">
                    <label htmlFor="pageSize">Rows per page:</label>
                    <select
                        id="pageSize"
                        value={pagination.page_size}
                        onChange={handlePageSizeChange}
                        className="border p-1 rounded"
                    >
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                </div>
                <div className="flex gap-2">
                    <button
                        onClick={() => handlePageChange(Math.max(1, pagination.page - 1))}
                        className="border px-3 py-1 rounded"
                        disabled={pagination.page === 1}
                    >
                        Previous
                    </button>
                    <button
                        onClick={() => handlePageChange(Math.min(pagination.total_pages, pagination.page + 1))}
                        className="border px-3 py-1 rounded"
                        disabled={pagination.page === pagination.total_pages}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Creditors;
