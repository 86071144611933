import React, { useState, useEffect } from 'react';

const AccountsTable = ({ data, level = 0 }) => {
    const [expandedRows, setExpandedRows] = useState({});

    // Function to expand all rows initially, including child rows
    const expandAllRows = (accounts) => {
        const expanded = {};
        const recursivelyExpand = (accountList) => {
            accountList.forEach((account) => {
                expanded[account.account_id] = true;
                if (account.children && account.children.length > 0) {
                    recursivelyExpand(account.children);
                }
            });
        };
        recursivelyExpand(accounts);
        return expanded;
    };

    useEffect(() => {
        setExpandedRows(expandAllRows(data));  // Expand all rows on initial render
    }, [data]);

    const toggleRowExpansion = (accountId) => {
        setExpandedRows((prevState) => ({
            ...prevState,
            [accountId]: !prevState[accountId]
        }));
    };

    const renderRows = (accounts) => {
        return accounts.map((account) => (
            <React.Fragment key={account.account_id}>
                <tr className="border-b">
                    <td className="px-4 py-2 w-2/4" style={{ paddingLeft: `${level * 20}px` }}>
                        {account.children && account.children.length > 0 && (
                            <button
                                onClick={() => toggleRowExpansion(account.account_id)}
                                className="mr-2 focus:outline-none"
                            >
                                {expandedRows[account.account_id] ? '▼' : '▶'}
                            </button>
                        )}
                        {account.account_name}
                    </td>
                    <td className="px-4 py-2 text-right">{account.total_debit || 0} LKR</td>
                    <td className="px-4 py-2 text-right">{account.total_credit || 0} LKR</td>
                </tr>
                {expandedRows[account.account_id] && account.children && (
                    <tr>
                        <td colSpan="3" className="p-0">
                            {/* Recursive call to render child accounts */}
                            <AccountsTable data={account.children} level={level + 1} />
                        </td>
                    </tr>
                )}
            </React.Fragment>
        ));
    };

    return (
        <table className="min-w-full border border-gray-200 text-left bg-white table-fixed">
            {level === 0 && (
                <thead>
                <tr className="bg-gray-100">
                    <th className="py-2 px-4 border-b text-left w-2/4">Account Name</th>
                    <th className="py-2 px-4 border-b text-right w-1/4">Debit</th>
                    <th className="py-2 px-4 border-b text-right w-1/4">Credit</th>
                </tr>
                </thead>
            )}
            <tbody>{renderRows(data)}</tbody>
        </table>
    );
};

export default AccountsTable;
