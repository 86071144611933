import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const CustomerSubLedger = () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
    const { customer_id } = useParams();
    const [subLedgerEntries, setSubLedgerEntries] = useState([]);
    const [filters, setFilters] = useState({
        start_date: "",
        end_date: ""
    });

    const fetchSubLedger = async () => {
        try {
            const response = await axios.get(
                `${backendUrl}/receivable/customer/getCustomerSubLedger/${customer_id}`,
                { params: { ...filters } }
            );
            setSubLedgerEntries(response.data.subLedgerEntries);
        } catch (error) {
            console.error("Error fetching sub-ledger:", error);
        }
    };

    useEffect(() => {
        fetchSubLedger();
    }, [customer_id, filters]);

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setFilters((prev) => ({ ...prev, [name]: value }));
    };

    return (
        <div className="mx-10 mt-5">
            <h2 className="text-3xl font-semibold mb-4">Customer Sub-Ledger</h2>

            {/* Date Range Filters */}
            <div className="flex flex-wrap gap-4 mb-5">
                <input
                    type="date"
                    name="start_date"
                    value={filters.start_date}
                    onChange={handleDateChange}
                    className="border p-2 rounded"
                />
                <input
                    type="date"
                    name="end_date"
                    value={filters.end_date}
                    onChange={handleDateChange}
                    className="border p-2 rounded"
                />
                <button onClick={fetchSubLedger} className="bg-blue-500 text-white px-4 py-2 rounded">
                    Apply Filters
                </button>
            </div>

            {/* Sub-Ledger Table */}
            <table className="min-w-full bg-white border border-gray-300">
                <thead>
                <tr>
                    <th className="px-4 py-2 border">Date</th>
                    <th className="px-4 py-2 border">Transaction ID</th>
                    <th className="px-4 py-2 border">Description</th>
                    <th className="px-4 py-2 border">Origin</th>
                    <th className="px-4 py-2 border">Related ID</th>
                    <th className="px-4 py-2 border">Related Date</th>
                    <th className="px-4 py-2 border">Amount</th>
                    <th className="px-4 py-2 border">Type</th>
                    <th className="px-4 py-2 border">Balance</th>
                </tr>
                </thead>
                <tbody>
                {subLedgerEntries.map((entry, index) => (
                    <tr key={index}>
                        <td className="px-4 py-2 border">{entry.date}</td>
                        <td className="px-4 py-2 border">{entry.transactionId}</td>
                        <td className="px-4 py-2 border">{entry.description}</td>
                        <td className="px-4 py-2 border">{entry.origin}</td>
                        <td className="px-4 py-2 border">{entry.relatedId}</td>
                        <td className="px-4 py-2 border">{entry.relatedDate}</td>
                        <td className="px-4 py-2 border">{entry.amount}</td>
                        <td className="px-4 py-2 border">{entry.type}</td>
                        <td className="px-4 py-2 border">{entry.balance}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default CustomerSubLedger;
